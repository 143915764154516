import { getEnvironment, isProductionEnv } from '../constants';

const environment = getEnvironment();

// ❗ This function exists because trying to inline the call to isProductionEnv() breaks the complier. ❗
const formatVisitorId = ( visitorId ) => isProductionEnv() ? visitorId : `${environment }:${ visitorId}`;


/**
 * There are two paths that consume the initiateAnalytics function:
 * 1. /contact/{secureId} - this route passes secureId as the visitorId
 * 2. /org/{orgCspCode} - this route passes orgCode as the visitorId
 */
export function initiateAnalytics( {visitorId , email, orgName} ) {
	window?.pendo?.initialize( {
		visitor: {
			id: formatVisitorId( visitorId ),
			email,
		},
		account: {
			id: orgName,
		}
	} );
}

/**
 * There are two paths that consume the attachIdentityToAnalytics function:
 * 1. /contact/{secureId} - this route passes secureId as the visitorId
 * 2. /org/{orgCspCode} - this route passes orgCode as the visitorId
 * 
 * Other values are omitted because they are already set in the pendo.initialize call
 */
export function attachIdentityToAnalytics( {visitorId , email, orgName} ) {
	window?.pendo?.identify( {
		visitor: {
			id: formatVisitorId( visitorId ),
			email,
		},
		account: {
			id: orgName,
		}
	} );

}
